import React from 'react';
import StyledFooter from '../styles/styledfooter.js';


const Footer = () => {
    return (<StyledFooter>
        Bharat Bhushan | Designed with <ion-icon name="heart"></ion-icon>
    </StyledFooter>);
}

export default Footer;