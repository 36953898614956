import React from 'react';
import StyledNav from '../styles/stylednav';


const Nav = () => {
    return (<StyledNav>
        <ul id="nav-ul">
            <li ><a href="#home" className="active">About</a></li>
            <li><a href="#career">Career & Work</a></li>
            <li><a href="#">Contact</a></li>
        </ul>
    </StyledNav >);
}

export default Nav;