import styled from 'styled-components';
import { FlexRow, FlexColumn } from '../theme/defaultStyles';

const StyledHome = styled.div`
    background-color: ${ ({ theme }) => theme.colors.dark};
    margin-top: 80px;
    height: 90vh;
    .home-header {
            ${FlexColumn}
             justify-content: space-evenly;
            height: 85%;
    }
    .social {
        ${FlexRow}
    }
    img{
        height: auto;
        width: 300px;
        border-radius: 50%;
        align-self: center;
    }

    .home-header a{
     margin-left: 2%;
     color: ${ ({ theme }) => theme.colors.themed};;
     font-size: 150%;
    }

    .line {
        width: 150px;
        height: 0px;
        border: 1px solid #FFCB14;
        margin: auto 0%;
    }

  
    @media only screen and (min-width: 768px) {
        img{
            height: 350px;
            width: 350px;
         }
        .home-header {
             height: 95%;
         }
        .line{
            width: 200px;
        }
    }

`

export default StyledHome;