import React, { Component } from 'react';
import styled from 'styled-components';
import Nav from './components/nav';
import Footer from './components/footer';
import Home from './components/home';
import Career from './components/career';


const StyledLayout = styled.div`
    & h1 {
        font-size: 350%
    }
`;

class App extends Component {
    render() {
        return (
            <StyledLayout>
                <div>
                    <Nav />
                    <Home id="home" />
                    <Career id="career" />
                    <Footer />
                </div>
            </StyledLayout>
        );
    }
}

export default App;