import styled from "styled-components";
import { FlexRow } from '../theme/defaultStyles';

const StyledNav = styled.nav`
 background-color:${ ({ theme }) => theme.colors.dark};
 color:  white;
 width: 100%;
 height: 10vh;
position: fixed;
left: 0;
z-index: 999;
top: 0;
 ul {
        ${FlexRow};
        justify-content: space-evenly;
        margin: auto;
        height: 100%;
        list-style: none;
        width: 100%;
    .active {
        color: #FFCB14;
        font-weight: 600;
    }

    li {
        transition: font-weight 0.2s linear, color 0.2s linear;
        cursor: pointer;
    }

    a:hover{
        font-weight: 600;
        color:  #FFCB14
    }
 }
 @media only screen and (min-width: 600px) {
        ul {
            width: 50%;
        }
    }
 `

export default StyledNav;