import React from 'react';
import StyledCareer from '../styles/styledcareer';
import Python from '../assets/tech/python.png';
import Aws from '../assets/tech/aws.png';
import Js from '../assets/tech/js.png';
import Git from '../assets/tech/git.png';
import Github from '../assets/tech/github.png';


const Career = (props) => {
    return (
        <StyledCareer id={props.id}>
            <div className="career-row">
                <div className="career-date">
                    Aug 2015 - Mar 2018
                </div>
                <div className="career-location">
                    Capgemini India
                </div>
            </div>
            <div className="career-row">
                <div className="career-date">
                    Mar 2018 - Present
                </div>
                <div className="career-location">
                    Amazon Web Services
                </div>
            </div>
            <div className="career-part" />
            <div className="work-row">
                <p className="desc">
                    I am an IT Geek who loves to code, solve problems, ride bike, workout everyday. I have been passionate about  learning new technologies and experimenting with it from my college days.<br /><br />
                    I’ve been associated with <br /><br />
                    <span className="assoc-link">@<a href="https://lakshay.xyz/">Lakshay</a></span> & <span className="assoc-link">@<a href="https://psycholabs.in/">PsychoLabs</a></span> <br /><br />for my freelance work. <br /><br />My Tech Stack includes:
                    </p>
            </div>
            <div className="work-tech">
                <img src={Python} alt="Python" />
                <img src={Js} alt="Javascript" />
                <img src={Aws} alt="AWS" />
                <img src={Git} alt="Git" />
                <img src={Github} alt="Github" />
            </div>
        </StyledCareer>);
}

export default Career;