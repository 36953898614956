import styled from "styled-components"
import { FlexRow } from '../theme/defaultStyles';

const StyledFooter = styled.footer`
    background-color:${ ({ theme }) => theme.colors.dark};
    color: ${ ({ theme }) => theme.colors.themed};
    width: 100%;
    height: 10vh;
    ${FlexRow}
    position: fixed;
    bottom: 0;
    left: 0;
    ion-icon {
        vertical-align: middle;
        align-self: center;
        margin-left: 5px;
    }

`

export default StyledFooter;