import React from 'react';
import main from '../assets/main.jpg';
import StyledHome from '../styles/styledhome';
import Footer from './footer';

const Home = (props) => {
    return (<StyledHome id={props.id}>
        <div className="home-header">
            <img src={main} alt="This was my photo" />
            <h4> IT GEEK | TECH ENTHUSIAST | GYM RAT</h4>
            <div className='social'>
                <div className="line" />
                <a href="https://www.facebook.com/iamthebharat.1"><ion-icon name="logo-facebook" /></a>
                <a href='https://www.linkedin.com/in/bhushanbharat993/'><ion-icon name="logo-linkedin" /></a>
                <a href="https://github.com/nalayakengineer/"><ion-icon name="logo-github" /></a>
                <a href="https://www.instagram.com/nalayak_engineer/"><ion-icon name="logo-instagram" /></a>
            </div>
        </div>
        <Footer />
    </StyledHome>);
}

export default Home;