import $ from 'jquery';

$(document).ready(function () {
    $(this).scrollTop(0);

    $(window).scroll(function (event) {
        var scrollPos = $(window).scrollTop();
        $('#nav-ul li a').each(function () {
            console.log($(this));

            var currLink = $(this);
            var refElement = $(currLink.attr("href"));
            if ((refElement.position().top - 300) <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                $('#nav-ul a').removeClass("active");
                currLink.addClass("active");
            }
            else {
                currLink.removeClass("active");
            }
        });
    });

});