import styled from 'styled-components';
import { FlexRow, FlexColumn } from '../theme/defaultStyles';

const StyledCareer = styled.div`
 /* Mobile Settings */
        width: 100%;
        background-color: ${ ({ theme }) => theme.colors.dark};
        height: 90vh;
         margin-top: 80px;
        .career-row {
            width: 100%;
            ${FlexColumn}
            justify-content: space-evenly;
           height: 100px;
           margin: 2% auto;
        }
        .career-date{ 
            color: ${ ({ theme }) => theme.colors.themed};
            font-weight: 600;  
            text-align: center;
            flex: 0 0 48%;
        }
        .career-location{
            flex: 0 0 48%;
             text-align: center;
        }
        .career-part {
             width: 300px;
             height: 0px;
            border: 1px solid #FFCB14;
            margin: auto ;
        }
        .work-row{
             margin-top: 50px;
            text-align: center;
            width: 100%;
        }
        .desc{
            margin: 30px;
        }
        .assoc-link, .assoc-link a{
            color: ${ ({ theme }) => theme.colors.themed};
            font-size: 120%;
        }
        .assoc-link a:hover{
            font-weight: 600;
        }
        img {
            height: 50px;
            width: 50px;
        }
        .work-tech {
            ${FlexRow}
            justify-content: space-evenly;
            margin: auto;
        }
/* Desktop Settings */
    @media only screen and (min-width: 768px ) {
        margin: auto;
        width: 50%;
         
         margin-bottom: 150px;
        .career-row {
            width: 100%;
            ${FlexRow}
            justify-content: space-evenly;
            height: 50px;
        }
        .career-date{ 
            color: ${ ({ theme }) => theme.colors.themed};
            font-weight: 600;  
            text-align: center;
            flex: 0 0 48%;
        }
        .career-location{
            flex: 0 0 48%;
            text-align: center;
        }
        .career-part {
            width: 400px;
            margin-top: 40px;
        }
        .desc {
            font-size: 120%;
        }
          .assoc-link, .assoc-link a{
            font-size: 140%;
        }
        img {
            height: 70px;
            width: 70px;
        }
    }
`
export default StyledCareer;